import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import styles from "./worlds.module.scss"


class WorldsTemplate extends React.Component {

  constructor(props) {
    super(props)
    const imageRoot = this.props.data.site.siteMetadata.imageRoot
    this.state = {
      world: {
        title: '',
        subTitle: '',
        name: '',
      },
      backgroundStyle: {
        backgroundImage: `url(${imageRoot}/iwakiri_labyrinth_7243_bw.png)`,
      },
      itemStyle: '',
      backgroundClassname: ''
    }
  }

  selectWorld = (title, subTitle, worldName) => {
    console.log(`select world.${title}`)
    const imageRoot = this.props.data.site.siteMetadata.imageRoot
    this.setState(
      {
        world: {
          title: title,
          subTitle: subTitle,
          name: worldName,
        },
        backgroundStyle: {
          backgroundImage: `url(${imageRoot}/worlds/${worldName}_top.jpg)`,
          transition: 'background-image 0.2s linear'
        },
        itemStyle: styles.mono,
        backgroundClassname: 'hovered'
      }
    )
  }

  resetWorld = () => {
    console.log(`reset world.`)
    const imageRoot = this.props.data.site.siteMetadata.imageRoot
    this.setState(
      {
        world: {
          title: '',
          subTitle: ''
        },
        backgroundStyle: {
          backgroundImage: `url(${imageRoot}/iwakiri_labyrinth_7243_bw.png)`,
          transition: 'background-image 0.2s linear'
        },
        itemStyle: '',
        backgroundClassname: ''
      }
    )
  }

  render() {
    const post = this.props.data.markdownRemark
    const lang = post.fields.slug.split('/')[1]
    const siteTitle = translate('site.name', lang)
    const headerSubTitle = translate('site.description', lang)
    const imageRoot = this.props.data.site.siteMetadata.imageRoot
    const { worlds, altWorlds } = this.props.pageContext
    worlds.sort(
      (a, b) => a.frontmatter.worldOrder - b.frontmatter.worldOrder
    )

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      centerMode: true,
      centerPadding: '10px',
    }
    const backgroundImages = []
    for (let world of worlds) {
      const worldName = world.fields.slug.split('/')[3]
      backgroundImages.push(`worlds/${worldName}_top.jpg`)
      backgroundImages.push(`worlds/${worldName}_square_bw.png`)
    }

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug} worlds={true}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          lang={lang}
          images={backgroundImages}
          slug={post.fields.slug}
        />
        <div className={`${styles.background} ${this.state.backgroundClassname}`} style={this.state.backgroundStyle}>
          <div className={styles.clip1}></div>
          <div className={styles.clip2}></div>
        </div>
        <div className={`main-content ${styles.main}`}>
          <div className="sub-content">
            <div className={styles.container}>
              <div className={styles.column}>
                {
                  worlds.filter(
                    (w, i) => i < 2
                  ).map(
                    (w, i) => {
                      const worldName = w.fields.slug.split('/')[3]
                      const altWorld = altWorlds.find(aw => aw.frontmatter.id === w.frontmatter.id)
                      const itemBackground = worldName === this.state.world.name || !this.state.world.name ? '' : '_bw'
                      return <div key={i} className={`${styles.item}`}
                        style={
                          {
                            backgroundImage: `url(${imageRoot}/worlds/${worldName}_square${itemBackground}.png)`,
                            backgroundSize: `cover`,
                            backgroundPosition: `top center`
                          }
                        }
                      >
                        <a className={styles.world} href={w.fields.slug}
                          onMouseOver={() => this.selectWorld(w.frontmatter.title, altWorld.frontmatter.title, worldName)}
                          onMouseLeave={() => this.resetWorld()}>
                          <h3 className={styles.worldTitle}>{w.frontmatter.title}</h3>
                          <h3 className={styles.worldSubTitle}>{altWorld.frontmatter.title}</h3>
                        </a>
                      </div>
                    }
                  )
                }
              </div>
              <div className={styles.column}>
                {
                  worlds.filter(
                    (w, i) => i >= 2 && i <= 4
                  ).map(
                    (w, i) => {
                      const worldName = w.fields.slug.split('/')[3]
                      const altWorld = altWorlds.find(aw => aw.frontmatter.id === w.frontmatter.id)
                      const itemBackground = worldName === this.state.world.name || !this.state.world.name ? '' : '_bw'
                      return <div key={i} className={`${styles.item} ${this.state.itemStyle}`}
                        style={
                          {
                            backgroundImage: `url(${imageRoot}/worlds/${worldName}_square${itemBackground}.png)`,
                            backgroundSize: `cover`,
                            backgroundPosition: `top center`
                          }
                        }
                      >
                        <a className={styles.world} href={w.fields.slug}
                          onMouseOver={() => this.selectWorld(w.frontmatter.title, altWorld.frontmatter.title, worldName)}
                          onMouseLeave={() => this.resetWorld()}>
                          <h3 className={styles.worldTitle}>{w.frontmatter.title}</h3>
                          <h3 className={styles.worldSubTitle}>{altWorld.frontmatter.title}</h3>
                        </a>
                      </div>
                    }
                  )
                }
              </div>
              <div className={styles.column}>
                {
                  worlds.filter(
                    (w, i) => i > 4
                  ).map(
                    (w, i) => {
                      const worldName = w.fields.slug.split('/')[3]
                      const altWorld = altWorlds.find(aw => aw.frontmatter.id === w.frontmatter.id)
                      const itemBackground = worldName === this.state.world.name || !this.state.world.name ? '' : '_bw'
                      return <div key={i} className={`${styles.item} ${this.state.itemStyle}`}
                        style={
                          {
                            backgroundImage: `url(${imageRoot}/worlds/${worldName}_square${itemBackground}.png)`,
                            backgroundSize: `cover`,
                            backgroundPosition: `top center`
                          }
                        }
                      >
                        <a className={styles.world} href={w.fields.slug}
                          onMouseOver={() => this.selectWorld(w.frontmatter.title, altWorld.frontmatter.title, worldName)}
                          onMouseLeave={() => this.resetWorld()}>
                          <h3 className={styles.worldTitle}>{w.frontmatter.title}</h3>
                          <h3 className={styles.worldSubTitle}>{altWorld.frontmatter.title}</h3>
                        </a>
                      </div>
                    }
                  )
                }
              </div>
            </div>
            <div className={styles.list}>
              {
                worlds.sort(
                  (a, b) => {
                    return a.frontmatter.order - b.frontmatter.order
                  }
                ).map(
                  (w, i) => {
                    const worldName = w.fields.slug.split('/')[3]
                    return <Link key={i} to={w.fields.slug} rel="world"
                      style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                      }} className={styles.worldTitle}>
                        <span className={styles.thumbnail}
                          style={
                            {
                              backgroundImage: `url(${imageRoot}/worlds/${worldName}_square.png)`,
                              backgroundSize: `cover`,
                              backgroundPosition: `top center`
                            }
                          }></span>
                        <span>{w.frontmatter.title}</span>
                    </Link>
                  }
                )
              }
            </div>
          </div>
          <div className={`sub-content ${styles.text}`}>
            <h2 className={styles.title}>{this.state.world.title ? this.state.world.title : translate('worlds.index', lang)}</h2>
            <hr></hr>
            <h3 className={styles.sub}>{this.state.world.subTitle ? this.state.world.subTitle : translate('worlds.index', lang === 'ja' ? 'en' : 'ja')}</h3>
          </div>
        </div>
      </Layout>
    )
  }
}

export default WorldsTemplate

export const pageQuery = graphql`
  query WorldsBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        imageRoot
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
